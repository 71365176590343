@import "all";

.SiteHeading {
  .rectangle-copy {
    max-width: 287px;
    background-color: $base-color;
    margin-left: 5px;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    background: none !important;

    .heading-container{
      width: 219px;
    }

    .rectangle-copy {
      height: auto;
      margin-left: 0 !important;
    }

    .heading-container,
    .mobile-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

}
